export class ShippingCompanyModel{
  id?: number;
  name?: string;
  phone?: string;
  email?: string;
  address?: string;
  constructor(company?: ShippingCompanyModel){
      company = company ? company : {};
      this.id = company.id ? company.id : 0;
      this.address = company.address ? company.address : '';
      this.email = company.email ? company.email : '' ;
      this.name = company.name ? company.name : '';
      this.phone = company.phone ? company.phone : '';
  }
}