import { BrowserModule } from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {MenuItems} from './shared/menu-items/menu-items';
import {BreadcrumbsComponent} from './layout/admin/breadcrumbs/breadcrumbs.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ng6-toastr-notifications';
import { AuthGuard } from './auth/auth.guard';
import { AdminGuard } from './auth/admin.guard';
import { TecherGuard } from './auth/techer.guard';
import { StudentrGuard } from './auth/student.guard';
import { UserService } from './shared/services/user.service';
import { NotifyService } from './core/services/notify.service';
import { AuthInterceptor } from './auth/auth.interceptor';
import { NgxLoadingModule } from 'ngx-loading';
import { FormsModule } from '@angular/forms';
@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    BreadcrumbsComponent
  ],
  imports: [
    NgxLoadingModule.forRoot({}),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
  ],
  schemas: [],
  
  providers: [
    MenuItems,
    NotifyService,
    AuthGuard,
    AdminGuard,
    TecherGuard,
    StudentrGuard,
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }],
  // providers: [MenuItems,NotifyService],
  bootstrap: [AppComponent]
})
export class AppModule { }
