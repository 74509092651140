import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ProductFeaturesModel } from "./producte.features.model";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
  })
  export class ProducteFeaturesService {
    baseUrl = environment.DevApiUrl;
     token = localStorage.getItem("token");
     httpOptions = {
      headers: new HttpHeaders({
         "Authorization": "Bearer " + this.token,
         "Content-Type": "application/json"
      })
    };
    constructor(private http: HttpClient) {
  
    }


  getAll(page:number): Observable<ProductFeaturesModel[]> {
 
    //debugger;
    return this.http
      .get<ProductFeaturesModel[]>(`${this.baseUrl}/ProductFeatures/GetAll/${page}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetCount(): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/ProductFeatures/GetCount`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  insert(payload: ProductFeaturesModel): Observable<boolean> {
    console.log(payload);
    //debugger;
    return this.http
      .post<boolean>(`${this.baseUrl}/ProductFeatures/Insert`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  update(payload: ProductFeaturesModel): Observable<boolean> {
    //debugger;
    return this.http
      .put<boolean>(`${this.baseUrl}/ProductFeatures/Update`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  delete(payload: ProductFeaturesModel): Observable<boolean> {
    //debugger;
    return this.http
      .delete<boolean>(`${this.baseUrl}/ProductFeatures/Delete/${payload.id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  
  GetById(id: number): Observable<ProductFeaturesModel> {
    //debugger;
      return this.http
      .get<ProductFeaturesModel>(`${this.baseUrl}/ProductFeatures/GetById/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
     }

}
