import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AdminComponent} from './layout/admin/admin.component';
import {AuthComponent} from './layout/auth/auth.component';
const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard/default',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: './theme/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'master-data',
        loadChildren: './pages/master-data/master-data.module#MasterDataModule'
      },
      {
        path: 'slider2-photo',
        loadChildren: './pages/slider2-photo/slider2-photo.module#Slider2PhotoModule'
      },
      {
        path: 'up-to-message',
        loadChildren: './pages/up-to-message/up-to-message.module#UpToMessageModule'
      },
      {
        path: 'gift-card',
        loadChildren: './pages/gift-card/gift-card.module#GiftCardModule'
      },
      {
        path: 'lists',
        loadChildren: './pages/custom-list/custom-list.module#ListModule'
      },
      {
        path: 'product-list/:Id',
        loadChildren: './pages/custom-list/components/product-custom-list/product-custom-list.module#ProductCustomListModule'
      },
      
      {
        path: 'admins',
        loadChildren: './pages/admin-data/admin-data.module#AdminDataModule'
      },
      {
        path: 'boxes',
        loadChildren: './pages/boxes/boxes.module#BoxesModule'
      },
      
      {
        path: 'reports',
        loadChildren: './pages/reports/reports.module#ReportsModule'
      },
      {
        path: 'orders',
        loadChildren: './pages/orders/orders.module#OrdersModule'
      },
      {
        path: 'coupon-codes',
        loadChildren: './pages/coupon-codes/coupon-codes.module#CouponCodeModule'
      },
      {
        path: 'jobs',
        loadChildren: './pages/jobs/jobs.module#JobsModule'
      },
      {
        path: 'shipping-companies',
        loadChildren: './pages/delevery/delevery.module#ShippingCompanyModule'
      },
      {
        path: 'supervisors',
        loadChildren: './pages/delevery/supervisors/supervisors.module#SubervisorsModule'
      },
      {
        path: 'dishes',
        loadChildren: './pages/dishes/dishes.module#DishesModule'
      },
      {
        path: 'navigation',
        loadChildren: './theme/navigation/navigation.module#NavigationModule'
      },
      {
        path: 'widget',
        loadChildren: './theme/widget/widget.module#WidgetModule'
      },
      {
        path: 'basic',
        loadChildren: './theme/ui-elements/basic/basic.module#BasicModule'
      },
      {
        path: 'advance',
        loadChildren: './theme/ui-elements/advance/advance.module#AdvanceModule'
      },
      {
        path: 'animations',
        loadChildren: './theme/ui-elements/animation/animation.module#AnimationModule'
      },
      {
        path: 'forms',
        loadChildren: './theme/forms/forms.module#FormsModule'
      },
      {
        path: 'bootstrap-table',
        loadChildren: './theme/table/bootstrap-table/bootstrap-table.module#BootstrapTableModule'
      },
      {
        path: 'data-table',
        loadChildren: './theme/table/data-table/data-table.module#DataTableModule'
      },
      {
        path: 'user',
        loadChildren: './theme/user/user.module#UserModule'
      },
      {
        path: 'email',
        loadChildren: './theme/email/email.module#EmailModule'
      },
      {
        path: 'crm-contact',
        loadChildren: './theme/crm-contact/crm-contact.module#CrmContactModule'
      },
      {
        path: 'task',
        loadChildren: './theme/task/task.module#TaskModule'
      },
      {
        path: 'editor',
        loadChildren: './theme/extension/editor/editor.module#EditorModule'
      },
      {
        path: 'invoice',
        loadChildren: './theme/extension/invoice/invoice.module#InvoiceModule'
      },
      {
        path: 'file-upload-ui',
        loadChildren: './theme/extension/file-upload-ui/file-upload-ui.module#FileUploadUiModule'
      },
      {
        path: 'calendar',
        loadChildren: './theme/extension/event-calendar/event-calendar.module#EventCalendarModule'
      },
      {
        path: 'charts',
        loadChildren: './theme/chart/chart.module#ChartModule'
      },
      {
        path: 'map',
        loadChildren: './theme/map/map.module#MapModule'
      },
      {
        path: 'simple-page',
        loadChildren: './theme/simple-page/simple-page.module#SimplePageModule'
      },
      // {
      //   path: 'landing',
      //   loadChildren: './theme/landing/landing.module#LandingModule'
      // },
      
      {
        path: 'slider-info',
        loadChildren: './pages/slider-info/slider-info.module#SliderInfoModule'
      },
      {
        path: 'store-location',
        loadChildren: './pages/store-locations/store-location.module#StoreLocationModule'
      },
      {
        path: 'user-messages',
        loadChildren: './pages/user-messages/user-message.module#UserMessageModule'
      },
      {
        path: 'corporate-gift',
        loadChildren: './pages/corporate-gifts/corporate-gifts.module#CorporateGiftModule'
      },
      {
        path: 'contact',
        loadChildren: './pages/contact-us/contact-us.module#ContactUsModule'
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        loadChildren: './pages/login/login.module#LoginModule'
      },
      {
        path: 'registration',
        loadChildren: './pages/registration/registration.module#RegistrationModule'
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
