import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CityModel } from './models/city.model';
import { CityWithRegionsModel } from './models/cityWithRegions.model';
import { RegionModel } from './models/region.model';

@Injectable({
  providedIn: 'root'
})
export class CityService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }

  getAll(page:number): Observable<CityModel[]> {
    return this.http
      .get<CityModel[]>(`${this.baseUrl}/City/GetAll/${page}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetMainCities(): Observable<CityModel[]> {
    return this.http
      .get<CityModel[]>(`${this.baseUrl}/City/GetMainCities`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetCount(): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/City/GetCount`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  createCity(payload: CityWithRegionsModel): Observable<boolean> {
    console.log(payload);
    return this.http
      .post<boolean>(`${this.baseUrl}/City/Insert`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  put(payload: CityWithRegionsModel): Observable<boolean> {
    return this.http
      .put<boolean>(`${this.baseUrl}/City/Update`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  delete(payload: CityModel): Observable<boolean> {
    return this.http
      .delete<boolean>(`${this.baseUrl}/City/Delete/${payload.id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetById(id: number): Observable<CityModel> {
      return this.http
      .get<CityWithRegionsModel>(`${this.baseUrl}/City/GetById/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
     }
 GetResionsByCityId(cityId: number): Observable<RegionModel[]>{
  return this.http
  .get<RegionModel[]>(`${this.baseUrl}/City/GetResionsByCityId/${cityId}`,this.httpOptions)
  .pipe(catchError((error: any) => Observable.throw(error.json())));
 }
}
