import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ShippingCompanyModel } from '../models/shipping-company.model';
import { ShippingCompanyWithSupervisorModel } from '../models/shipping-company-with-supervisor.model';

@Injectable({
  providedIn: 'root'
})
export class ShippingCompanyService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }
  getAll(page: number): Observable<ShippingCompanyModel[]> {
    return this.http
      .get<ShippingCompanyModel[]>(`${this.baseUrl}/ShippingCompany/GetAll/${page}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetCount(): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/ShippingCompany/GetCount`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  createShippingCompany(payload: ShippingCompanyWithSupervisorModel): Observable<boolean> {
    return this.http
      .post<boolean>(`${this.baseUrl}/ShippingCompany/Insert`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  put(payload: ShippingCompanyWithSupervisorModel): Observable<boolean> {
    return this.http
      .put<boolean>(`${this.baseUrl}/ShippingCompany/Update`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  delete(payload: ShippingCompanyModel): Observable<boolean> {
    //debugger;
    return this.http
      .delete<boolean>(`${this.baseUrl}/ShippingCompany/Delete/${payload.id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetById(id: number): Observable<ShippingCompanyModel> {
    //debugger;
      return this.http
      .get<ShippingCompanyWithSupervisorModel>(`${this.baseUrl}/ShippingCompany/GetById/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
     }
}
