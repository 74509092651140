import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CategoryModel } from './models/Category.model';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }

  getAll(page:number): Observable<CategoryModel[]> {
 
    //debugger;
    return this.http
      .get<CategoryModel[]>(`${this.baseUrl}/Category/GetAll/${page}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetCount(): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/Category/GetCount`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  createCategory(payload: CategoryModel): Observable<boolean> {
    console.log(payload);
    //debugger;
    return this.http
      .post<boolean>(`${this.baseUrl}/Category/Insert`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  put(payload: CategoryModel): Observable<boolean> {
    //debugger;
    return this.http
      .put<boolean>(`${this.baseUrl}/Category/Update`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  delete(payload: CategoryModel): Observable<boolean> {
    //debugger;
    return this.http
      .delete<boolean>(`${this.baseUrl}/Category/Delete/${payload.id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  
  GetById(id: number): Observable<CategoryModel> {
    //debugger;
      return this.http
      .get<CategoryModel>(`${this.baseUrl}/Category/GetById/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
     }

 

 
}
