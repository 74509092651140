import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProductModel } from './models/Product.model';
import { ProductWithDetailsModel } from './models/product-with-details.model';
import { ProductForAddOrderModel } from './models/product-for-add-order.model';
@Injectable({
  providedIn: 'root'
})
export class ProductService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }
  getAll(page:number): Observable<ProductModel[]> {
    return this.http
      .get<ProductModel[]>(`${this.baseUrl}/Product/GetAll/${page}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetAllForAddOrder(): Observable<ProductForAddOrderModel[]> {
    //debugger;
    return this.http
      .get<ProductForAddOrderModel[]>(`${this.baseUrl}/Product/GetAllForAddOrder`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  
  GetCount(): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/Product/GetCount`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetAllForBranchAdmin(page:number): Observable<ProductModel[]> {
    return this.http
      .get<ProductModel[]>(`${this.baseUrl}/Product/GetAllForBranchAdmin/${page}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetCountForBranchAdmin(): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/Product/GetCountForBranchAdmin`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  
  getByBranchId(id:number): Observable<ProductModel[]> {
    return this.http
      .get<ProductModel[]>(`${this.baseUrl}/Product/GetByBranchId/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetNotInThisBranch(id:number): Observable<ProductModel[]> {
    return this.http
      .get<ProductModel[]>(`${this.baseUrl}/Product/GetNotInThisBranch/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  getByDishDesignId(id:number): Observable<ProductModel[]> {
    return this.http
      .get<ProductModel[]>(`${this.baseUrl}/Product/GetByDishDesignId/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetNotInThisDishDesign(id:number): Observable<ProductModel[]> {
    return this.http
      .get<ProductModel[]>(`${this.baseUrl}/Product/GetNotInThisDishDesign/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  createProduct(payload: ProductWithDetailsModel): Observable<boolean> {
    console.log(payload);
    return this.http
      .post<boolean>(`${this.baseUrl}/Product/Insert`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  put(payload: ProductWithDetailsModel): Observable<boolean> {
    return this.http
      .put<boolean>(`${this.baseUrl}/Product/Update`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  delete(payload: ProductModel): Observable<boolean> {
    return this.http
      .delete<boolean>(`${this.baseUrl}/Product/Delete/${payload.id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetById(id: number): Observable<ProductModel> {
      return this.http
      .get<ProductWithDetailsModel>(`${this.baseUrl}/Product/GetById/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
     }
}
