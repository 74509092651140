export class ProductFeaturesModel{
  id      ?: number;
  titleAr  ?: string;
  titleEn  ?: string;
  titleFr  ?: string;
  iconUrl?: string;


  constructor(item?:ProductFeaturesModel){
    item=item?item:{};
      this.id       = item.id      ?item.id      :0;
      this.titleAr   = item.titleAr  ?item.titleAr  :'';
      this.titleEn   = item.titleEn  ?item.titleEn  :'';
      this.titleFr   = item.titleFr  ?item.titleFr  :'';
      this.iconUrl = item.iconUrl?item.iconUrl:'';
  }
}