import { EmailDataAfterOrderModel } from './../models/email-data-after-order.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { OrderModel } from '../models/order.model';
import { OrderDetailsModel } from '../models/order-details.model';
import { OrderForAddModel } from '../models/order-for-add.model';
import { ExecutePaymentRequestModel } from '../models/ExecutePaymentRequest.model';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }
  getAll(page: number): Observable<OrderModel[]> {
    return this.http
      .get<OrderModel[]>(`${this.baseUrl}/Order/GetAll/${page}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetTodayOrders(page: number): Observable<OrderModel[]> {
    return this.http
      .post<OrderModel[]>(`${this.baseUrl}/Order/GetTodayOrders/${page}`,{date:new Date(new Date().toString() + ' UTC')}, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetByShippingCompanySupervisor(page: number): Observable<OrderModel[]> {
    return this.http
      .get<OrderModel[]>(`${this.baseUrl}/Order/GetByShippingCompanySupervisor/${page}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetByStatus(status: number): Observable<OrderModel[]> {
    return this.http
      .get<OrderModel[]>(`${this.baseUrl}/Order/GetByStatus/${status}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetCount(): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/Order/GetCount`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetCountForAllForAdminBranch(): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/Order/GetCountForAllForAdminBranch`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetCountForTodayForAdminBranch(): Observable<number> {
    return this.http
      .post<number>(`${this.baseUrl}/Order/GetCountForTodayForAdminBranch`,{date:new Date(new Date().toString() + ' UTC')}, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetCountForToday(): Observable<number> {
    return this.http
      .post<number>(`${this.baseUrl}/Order/GetCountForToday`,{date:new Date(new Date().toString() + ' UTC')}, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  
  GetAllForAdminBranch(page: number): Observable<OrderModel[]> {
    return this.http
      .get<OrderModel[]>(`${this.baseUrl}/Order/GetAllForAdminBranch/${page}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetTodayForAdminBranch(page: number): Observable<OrderModel[]> {
    return this.http
      .post<OrderModel[]>(`${this.baseUrl}/Order/GetTodayForAdminBranch/${page}`,{date:new Date(new Date().toString() + ' UTC')}, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetCountForSupervisor(): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/Order/GetCountForSupervisor`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  createOrder(payload: OrderModel): Observable<boolean> {
    return this.http
      .post<boolean>(`${this.baseUrl}/Order/Insert`, payload, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  InsertByAdmin(payload: OrderForAddModel): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/Order/InsertByAdmin`, payload, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  put(payload: OrderModel): Observable<boolean> {
    return this.http
      .put<boolean>(`${this.baseUrl}/Order/Update`, payload, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  delete(payload: OrderModel): Observable<boolean> {
    return this.http
      .delete<boolean>(`${this.baseUrl}/Order/Delete/${payload.id}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetById(id: number): Observable<OrderModel> {
      return this.http
      .get<OrderModel>(`${this.baseUrl}/Order/GetById/${id}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
     }
  GetOrderDetails(id: number): Observable<OrderDetailsModel> {
      return this.http
      .get<OrderDetailsModel>(`${this.baseUrl}/Order/GetOrderDetails/${id}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
     }
     MyFatoorahPay(item?:ExecutePaymentRequestModel): Observable<any> {
      //debugger;
      return this.http
        .post<any>(`${this.baseUrl}/MyFatoorahPayment/ExecutePayment`,item, this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error.json())));
    }
    InitiatePayment(item:number): Observable<any> {
      //debugger;
      return this.http
        .post<any>(`${this.baseUrl}/MyFatoorahPayment/InitiatePayment`,item, this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error.json())));
    }
    SendEmail(item:EmailDataAfterOrderModel): Observable<any> {
      //debugger;
      return this.http
        .post<any>(`${this.baseUrl}/Order/SendEmail`,item, this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error.json())));
    }
    
}
